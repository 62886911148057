<section class="nav-layout">

  <div class="d-flex gap-2">
    <div class="side">
      <app-side-menu></app-side-menu>

    </div>
    <div class="window" #window>
      <app-navbar></app-navbar>
      <main class="py-5 p-0 pe-2 px-md-3">
        <router-outlet></router-outlet>

      </main>
    </div>
  </div>
</section>

<!-- toaster -->
<div class="custom-alert d-flex gap-4 toaster" *ngIf="toaster?.visible" [ngClass]="toaster.statue">
  <div class="icon">
    @if (toaster.statue == 'success') {
    <i class="fa-solid fa-circle-check"></i>
    } @else if (toaster.statue == 'error') {
    <i class="fa-solid fa-circle-xmark"></i>
    } @else {
    <i class="fa-solid fa-circle-exclamation"></i>
    }
  </div>
  <div class="text flex-grow-1">
    <div class="head d-flex align-items-start justify-content-between">
      <h5>{{toaster.title}}</h5>
      <i class="fa-solid fa-xmark curser-pointer" (click)="toaster.visible = false"></i>
    </div>
    <p [innerHTML]="toaster.message"></p>
  </div>
</div>
import { Directive, HostListener, inject } from '@angular/core';
import { AuthService } from '../../modules/auth/services/auth.service';

@Directive({
  selector: '[StorageListener]',
  standalone: true
})
export class StorageListenerDirective {

  private _AuthService = inject(AuthService);

  @HostListener('window:storage', ['$event']) localStorageListener(event: any) {
    this._AuthService.logout();
  }
}

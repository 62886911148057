<section class="navbar  w-100">
  <div class="container-fluid ">
    <div class="row w-100 px-3 justify-content-between align-items-center">
      <!-- i will keep it thus to when get job for it -->
      <!-- <div class="col-8 col-lg-4">
        <div class="input-main-icon">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="text" placeholder="Search" pInputText [(ngModel)]="searchTerm" id="search" />
          </span>
        </div>
      </div> -->

      <div class="offset-8 col-4">
        <div class="icons-nav d-flex justify-content-end align-items-center">
          <!-- * user avatar -->
          <span *ngIf="userInfo">
            <img class="rounded-circle curser-pointer" (click)="_Router.navigate(['/settings/setting'])"
              routerLinkActive="router-link-active"
              [src]="userInfo.avatar ? userInfo.avatar : 'assets/imgs/navbar/Oval.png'" [alt]="userInfo.name"
              width="25px" height="25px">
          </span>
          <div class="notification position-relative">
            <i class="pi pi-bell"></i>
            <!-- <div class="dropdown">
              <div class="dropdown">Exchanges</div>
              <div class="dropdown">Top-Ups</div>
              <div class="dropdown">Withdraws</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMessageSended, ITicketDetails, ITicketsResponse, ITicketStatus } from '../interface/ticket';
import { environment } from '../../../../environments/enviroment';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private _HttpClient = inject(HttpClient);
  public statusCounts = new BehaviorSubject<ITicketStatus>({} as ITicketStatus);

  getTickets( page: number, limit: number, ticketNumber?: string, orderNumber?: string, statue?: string): Observable<ITicketsResponse> {

    let optionalParams = [];

    if (ticketNumber) optionalParams.push(`&ticketNumber=${ticketNumber}`);
    if (orderNumber) optionalParams.push(`&orderId=${orderNumber}`);
    if (statue) optionalParams.push(`&status=${statue.toUpperCase()}`);

    return this._HttpClient.get<ITicketsResponse>(`${environment.apiUrl}/v1/ticket/admin-tickets?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}`);
  }

  getTicketDetails(id: string): Observable<ITicketDetails> {
    return this._HttpClient.get<ITicketDetails>(`${environment.apiUrl}/v1/ticket/admin-messages/${id}` );
  }

  sendMessage(data: IMessageSended): Observable<any> {
    return this._HttpClient.post( `${environment.apiUrl}/v1/ticket/admin-send-message`, data );
  }

  changeStatus(status: string, id: string): Observable<any> {
    return this._HttpClient.patch( `${environment.apiUrl}/v1/ticket/change-status/${id}/${status}`, {} );
  }

  getCountTicketsByStatus(): Observable<ITicketStatus> {
    return this._HttpClient.get<ITicketStatus>(`${environment.apiUrl}/v1/ticket/status-counts`);
  }
}

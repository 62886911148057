 import { Component, OnInit, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { CommonModule } from '@angular/common';
import { SideMenu, SideMenuService } from './services/side-menu.service';
import { LivePoint } from './assets/live-point';
import { StorageListenerDirective } from '../../../shared/directives/storage-listener.directive';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [PanelMenuModule, CommonModule, StorageListenerDirective],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
})
export class SideMenuComponent implements OnInit {
  // responsive width
  public w480 = false;
  public w768 = false;
  public w991 = false;
  public w1150 = false;
  public w1440 = false;
  public desktopWidth = false;
  public sideMenuOpened = true;
  // inner components like user details
  public innerCompoRoute!: string;

  // current module
  public nameOfCurrentModule!: string;

  public _Router = inject(Router);
  private __AuthService = inject(AuthService);
  private _SideMenuService = inject(SideMenuService);
  public _LivePoint = new LivePoint();
  // items
  public itemsFooter: SideMenu[] = this._SideMenuService.itemsFooter;
  public items: SideMenu[] = this._SideMenuService.items;

  ngOnInit(): void {
    this.getWindowWidth();
    this.subscribeOnBehaviorSubjects();
    this.dataOfRoute(location.pathname);
    this.watchRoute();
    if ( this.w991 || this.w1150) this.openSideMenu(false);
    this.livePointRunning();
  }

  livePointRunning() {
    this._LivePoint.getExchangesForGetLengthsOfStatus();
    this._LivePoint.getWithdrawsForGetLengthsOfStatus();
    this._LivePoint.getTopUpsForGetLengthsOfStatus();
    this._LivePoint.getContactsCounts();
    this._LivePoint.getCountTicketsByStatus();
    this._LivePoint.getNewGatewayRequestCounts();
    this._LivePoint.getReset2FARequestCounts();
  }

  // watch route changes
  watchRoute() {
    
    this._Router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (sessionStorage.getItem('2FA') == 'false') {
          this._Router.navigateByUrl('/auth/twofa-settings');
        }
        this.dataOfRoute(event.url);
      }
    });
  }

  // the boss
  dataOfRoute(route: string) {

    if (this.w480 || this.w768 ) this.openSideMenu(false);

    let routeName = route.split('/')

    // extract module route and push in this array

    // and send array to method for convert to string and save in
    let nameOfCurrentModule = routeName[1];

    this.innerCompoRoute = routeName[2];
    // the activation link built on nameOfCurrentModule ;
    // if open standalone comp name activation style doesn't work;
    // this line make activation style work
    if (!this.innerCompoRoute) this.innerCompoRoute = nameOfCurrentModule;

    // for style of routerLink active
    this.items.filter((item) => {
      if (item.items) {
        item.lengthOfItems = item.items.length;
      }
      if (nameOfCurrentModule == item.class1 || nameOfCurrentModule == item.class2 || nameOfCurrentModule == item.class3 || nameOfCurrentModule == item.class4) {
        item.routeActive = true;
        if (item.dropdown) {
          if (this.sideMenuOpened) item.expand = true;
          this._SideMenuService.w1150.subscribe(w1150 =>
            this._SideMenuService.w1440.subscribe(w1440 =>
              this._SideMenuService.desktopWidth.subscribe(desktopWidth => {
                if (item.dropdown && item.routeActive && (w1150 || w1440 || desktopWidth)) {
                  if (this.sideMenuOpened) item.expand = true;
                } else {
                }
              })))
        }
      } else {
        if (item.dropdown) item.expand = false;
        item.routeActive = false;

      }

      // for style of routerLink active of links of manage users dropdown
      item.items?.filter((ele, i) => {
        if (ele.class1 == this.innerCompoRoute || ele.class2 == this.innerCompoRoute || ele.class3 == this.innerCompoRoute || ele.class4 == this.innerCompoRoute) {
          if (this.sideMenuOpened) item.expand = true;
          ele.routeActive = true;
          if (!this.sideMenuOpened) item.expand = false;
          return;
        }
      });

      if (item.class1 == 'footer-pages') {
        item.items?.forEach((link) => {
          if (link.class1 == this.innerCompoRoute) {
            if (this.sideMenuOpened) item.expand = true;
            this._SideMenuService.w1150.subscribe(w1150 =>
              this._SideMenuService.w1440.subscribe(w1440 =>
                this._SideMenuService.desktopWidth.subscribe(desktopWidth => {
                  if (item.dropdown && item.routeActive && (w1150 || w1440 || desktopWidth)) {
                    if (this.sideMenuOpened) item.expand = true;
                  } else {
                  }
                })))
            item.routeActive = true;
          }
        });
      }
    });

    this.itemsFooter.map((item) => {
      if (item.items) item.lengthOfItems = item.items.length;
      if (item.dropdown) {
        if (item.expand) item.routeActive = true;
        else item.routeActive = false;
      }
      if (item.class1 == nameOfCurrentModule) {
        item.routeActive = true;
        if (this.sideMenuOpened) item.expand = true;
        if (item.dropdown && item.routeActive) {
          if (this.sideMenuOpened) item.expand = true;
        }
      } else {
        item.routeActive = false;
        item.expand = false;
      }
      item.items?.map((ele) => {
        if ((ele.class1 || ele.class2 || ele.class3 || ele.class4) == this.innerCompoRoute) {
          if (this.sideMenuOpened) item.expand = true;
          ele.routeActive = true;
        }
      });
    });
  }
  

  // if any dropdown is expanded others will collapse
  collapseDropdowns(dropdown: SideMenu) {
    this.itemsFooter.map((item) => {
      if (item.dropdown) item.expand = false;
    });
    let array = this.items.filter((ele) => ele.dropdown);
    array.map((ele) => {
      ele.expanded = false;
    });
    dropdown.expanded = true;
    this.items.map((item) => {
      if (item.class1 == dropdown.class1) item = dropdown;
      if (item.dropdown && !item.expanded) item.expand = false;
    });
  }

  // if any dropdown is expanded others will collapse
  collapseDropdownsFooter(dropdown: SideMenu) {
    this.items.map((item) => {
      if (item.dropdown) item.expand = false;
    });
    let array = this.itemsFooter.filter((ele) => ele.dropdown);
    array.map((ele) => {
      ele.expanded = false;
    });
    dropdown.expanded = true;
    this.itemsFooter.map((item) => {
      if (item.class1 == dropdown.class1) item = dropdown;
      if (item.dropdown && !item.expanded) item.expand = false;
    });
  }

  convertArrayToString(array: string[]) {
    let arrayAfterToString = array.join('').split(',').toString();
    return arrayAfterToString;
  }

  getWindowWidth() {
    let count = 0;
    // check first time
    if (window.innerWidth <= 480) this._SideMenuService.w480.next(true);
    else if (window.innerWidth > 480) this._SideMenuService.w480.next(false);

    if (window.innerWidth <= 768 && window.innerWidth > 480) this._SideMenuService.w768.next(true);
    else if (window.innerWidth > 768) this._SideMenuService.w768.next(false);

    if (window.innerWidth <= 991 && window.innerWidth > 768) this._SideMenuService.w991.next(true);
    else if (window.innerWidth > 991) this._SideMenuService.w991.next(false);

    if (window.innerWidth <= 1150 && window.innerWidth > 991) this._SideMenuService.w1150.next(true);
    else if (window.innerWidth > 1150) this._SideMenuService.w1150.next(false);

    if (window.innerWidth <= 1440 && window.innerWidth > 1100) this._SideMenuService.w1440.next(true);
    else if (window.innerWidth > 1440) this._SideMenuService.w1440.next(false);

    if (window.innerWidth >= 1440) this._SideMenuService.desktopWidth.next(true);
    else if (window.innerWidth < 1440) this._SideMenuService.desktopWidth.next(false);

    // check at resizing
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1150) this.openSideMenu(false);
      else this.openSideMenu(true);
      
      count++;

      if (window.innerWidth <= 480) this._SideMenuService.w480.next(true);
      else if (window.innerWidth > 480) this._SideMenuService.w480.next(false);

      if (window.innerWidth <= 768 && window.innerWidth > 480) this._SideMenuService.w768.next(true);
      else if (window.innerWidth > 768) this._SideMenuService.w768.next(false);

      if (window.innerWidth <= 991 && window.innerWidth > 768) this._SideMenuService.w991.next(true);
      else if (window.innerWidth > 991) this._SideMenuService.w991.next(false);

      if (window.innerWidth <= 1150 && window.innerWidth > 991) this._SideMenuService.w1150.next(true);
      else if (window.innerWidth > 1150) this._SideMenuService.w1150.next(false);

      if (window.innerWidth <= 1440 && window.innerWidth > 1150) this._SideMenuService.w1440.next(true);
      else if (window.innerWidth > 1440) this._SideMenuService.w1440.next(false);

      if (window.innerWidth >= 1440) this._SideMenuService.desktopWidth.next(true);
      else if (window.innerWidth < 1440) this._SideMenuService.desktopWidth.next(false);
    });

  }

  // subscribe on behavior subject of side menu service for responsive view
  subscribeOnBehaviorSubjects() {
    this._SideMenuService.desktopWidth.subscribe((res) => {
      this.desktopWidth = res;
    });
    this._SideMenuService.w1440.subscribe((res) => {
      this.w1440 = res;

    });
    this._SideMenuService.w1150.subscribe((res) => {
      this.w1150 = res;
    });
    this._SideMenuService.w991.subscribe((res) => {
      this.w991 = res;
    });

    this._SideMenuService.w768.subscribe((res) => {
      this.w768 = res;
    });
    this._SideMenuService.w480.subscribe((res) => {
      this.w480 = res;
    });
  }

  // if side menu closed then click on dropdown side menu will opening for appear sup menu
  openSideMenu(opened?: boolean) {
    this._SideMenuService.sideMenuOpened_BS.next(opened!);
    if (opened || opened == false) this.sideMenuOpened = opened;
    this._SideMenuService.desktopWidth.subscribe((res) => {
      if (!this.sideMenuOpened && (!res || !this.w1440))
        this.items.map((item) => {
          if (item.dropdown) {
            item.expanded = false;
            item.expand = false;
          }
        });

      if (!this.sideMenuOpened && (!res || !this.w1440))
        this.itemsFooter.map((item) => {
          if (item.dropdown) {
            item.expand = false;
            item.expanded = false;
          }
        });
    });
  }

  logout(): void {
    this.__AuthService.logout();
  }
}

import { GatewaysService } from './modules/gateway/services/gateways.service';
import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './modules/auth/services/auth.service';
import { UserService } from './modules/users/services/user.service';
import { WalletsService } from './modules/wallets/services/wallets.service';
import { CurrencyService } from './modules/currencies/services/currency.service';
import { ExchangeDirectionsService } from './modules/exchange/services/exchange-directions.service';
import { ExchangesService } from './modules/orders/services/exchanges.service';
import { WithdrawService } from './modules/orders/services/withdraw.service';
import { TopUpService } from './modules/orders/services/top-up.service';
import { Subscription } from 'rxjs';
    

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [
    AuthService,
    UserService,
    WalletsService,
    GatewaysService,
    CurrencyService,
    ExchangeDirectionsService,
    ExchangesService,
    WithdrawService,
    TopUpService,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public noData = true;
  public isLoading: boolean = false;
  public total!: number;

  private _CurrencyService = inject(CurrencyService);
  private _Subscription: Subscription = new Subscription();
  private _AuthService = inject(AuthService);
  private _Router = inject(Router);

  ngOnInit(): void {
    

    if (sessionStorage.getItem('2FA') == 'false') {
      this._Router.navigateByUrl('/auth/twofa-settings');
    }
  }



}

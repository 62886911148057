import { Injectable, inject } from '@angular/core';
import { IWithdrawDetails, IWithdrawResponse } from '../interfaces/withdraw';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/enviroment';
import { IStatus } from '../interfaces/exchanges';

@Injectable({
  providedIn: 'root',
})
export class WithdrawService {
  public status: BehaviorSubject<any> = new BehaviorSubject(null);
  public userToken = sessionStorage.getItem('adminUserToken');
  private _HttpClient = inject(HttpClient);


  getWithdraws(page: number, limit: number, receiveMethod?: string, referenceId?: string, statue?: string): Observable<IWithdrawResponse> {
    console.log('statue: ', statue);
    if (statue?.toLowerCase() == 'onhold') statue = 'on_hold'
    let optionalParams = [];
    if (receiveMethod) optionalParams.push(`&receiveMethod=${receiveMethod}`);
    if (referenceId) optionalParams.push(`&withdrawId=${referenceId}`);
    if (statue) optionalParams.push(`&status=${statue.toUpperCase()}`);

    return this._HttpClient.get<IWithdrawResponse>(`${environment.apiUrl}/v1/withdraw/withdraw-list-admin?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}`);
  }

  getWithdrawDetails(id: string): Observable<IWithdrawDetails> {
    return this._HttpClient.get<IWithdrawDetails>(`${environment.apiUrl}/v1/withdraw/admin-withdraw-details/${id}`);
  }

  changeStatusOfWithdraw(id: string, statue: string): Observable<any> {
    if (statue == 'ON HOLD') statue = 'ON_HOLD';
    return this._HttpClient.patch(`${environment.apiUrl}/v1/withdraw/change-status/${id}/${statue}`, {});
  }

  getStatusOfWithdraw(): Observable<IStatus> {
    return this._HttpClient.get<IStatus>(`${environment.apiUrl}/v1/withdraw/admin-withdraw-stats`);
  }
}

import { inject } from "@angular/core";
import { ExchangesService } from "../../../../modules/orders/services/exchanges.service";
import { SideMenuService } from "../services/side-menu.service";
import { WithdrawService } from "../../../../modules/orders/services/withdraw.service";
import { TopUpService } from "../../../../modules/orders/services/top-up.service";
import { TicketService } from "../../../../modules/ticket/services/ticket.service";
import { tap, noop } from "rxjs";
import { ContactUsService } from "../../../../modules/contact-us/services/contact-us.service";
import { RequestGatewayService } from "../../../../modules/new-gateway/services/request-gateway.service";
import { TwoFARequestsService } from "../../../../modules/reset-2fa-requests/services/two-farequests.service";

export class LivePoint {
  public _ExchangesService = inject(ExchangesService);
  public _WithdrawService = inject(WithdrawService);
  public _TopUpService = inject(TopUpService);
  public _SideMenuService = inject(SideMenuService);
  public _TicketService = inject(TicketService);
  public _ContactUsService = inject(ContactUsService);
  public _RequestGatewayService = inject(RequestGatewayService);
  public _TwoFARequestsService = inject(TwoFARequestsService);
  public items = this._SideMenuService.items;
  public itemsFooter = this._SideMenuService.itemsFooter;


  // ! For Orders Dropdown

  // ? get length of status os exchanges
  getExchangesForGetLengthsOfStatus() {
    this._ExchangesService.getStatusOfExchanges().pipe( tap(
      status => {
        this._ExchangesService.status.next(status);
        this._ExchangesService.status.subscribe({
          next: (res) => {
            this.items.map((ele) => {
              if (ele.class1 == 'orders' && res?.processing) {
                ele.items?.map((item) => {
                  if (item.class1 == 'all-exchanges') {
                    this.runningLivePointForOrders();
                    item.eventValue = res?.processing;
                  }
                });
              }
            });
          },
        });
      }
    )).subscribe( noop, err => console.log(err))
  }

  // ? get length of status os withdraws
  getWithdrawsForGetLengthsOfStatus() {
    this._WithdrawService.getStatusOfWithdraw().pipe( tap(
      status => {
        this._WithdrawService.status.next(status);
        this._WithdrawService.status.subscribe((res) => {
          this.items.map((ele) => {
            if (ele.class1 == 'orders' && res?.processing) {
              ele.items?.map((item) => {
                if (item.class1 == 'withdraw-list') {
                  this.runningLivePointForOrders();
                  item.eventValue = res?.processing;
                }
              });
            }
          });
        });
      }
    )).subscribe( noop, err => console.log(err))
  }

  // ? get length of status os top ups
  getTopUpsForGetLengthsOfStatus() {
    this._TopUpService.getStatusOfTopUp().pipe( tap( 
      status => {
        this._TopUpService.status.next(status);
        this._TopUpService.status.subscribe((res) => {
          this.items.map((ele) => {
            if (ele.class1 == 'orders' && res?.processing) {
              ele.items?.map((item) => {
                if (item.class1 == 'tob-up-list') {
                  this.runningLivePointForOrders();
                  item.eventValue = res?.processing;
                }
              });
            }
          });
        });
      }
    )).subscribe( noop, err => console.log(err))
  }

  // // ? set counts
   runningLivePointForOrders() {
    this.items.map(ele => {
      if (ele.class1 == 'orders') {
        ele.items?.map( item => {
          if (item.eventValue) {
            ele.event = true;
          }
        })
      }
    })
  }
  
  // ! ============================================================

  // ! For Tickets Dropdown

  // ? tickets
  getCountTicketsByStatus() {
    this._TicketService.getCountTicketsByStatus().pipe(tap(
      status => {
        this._TicketService.statusCounts.next(status)
        this._TicketService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket' && res.open) {
              ele.items?.map(item => {
                if (item.class1 == 'ticket-list') {
                  item.eventValue = res.open;
                  this.runningLivePointForTickets()
                }
              })
            }

          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  // ? contact us
  getContactsCounts() {
    this._ContactUsService.getStatusCounts().pipe(tap(
      count => {
        this._ContactUsService.statusCounts.next(count);
        this._ContactUsService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket' && res.removedFalse) {
              ele.items?.map(item => {
                if (item.class1 == 'contact-us-list') {
                  item.eventValue = res.removedFalse;
                  this.runningLivePointForTickets()
                }
              })
            }
          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  // ? request new gateway
  getNewGatewayRequestCounts() {
    this._RequestGatewayService.getNewGatewayRequestCounts().pipe(tap(
      count => {
        this._RequestGatewayService.statusCounts.next(count);
        this._RequestGatewayService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket'  && res.removedFalse) {
              ele.items?.map(item => {
                if (item.class1 == 'requests-new-gateways') {
                  item.eventValue = count.removedFalse;
                  this.runningLivePointForTickets()
                }
              })
            }
          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  // ? reset 2FA
  getReset2FARequestCounts() {
    this._TwoFARequestsService.getReset2FARequestCounts().pipe(tap(
      count => {
        this._TwoFARequestsService.statusCounts.next(count);
        this._TwoFARequestsService.statusCounts.subscribe(res => {
          this.items.map(ele => {
            if (ele.class1 == 'ticket' && res.PENDING) {
              ele.items?.map(item => {
                if (item.class1 == 'reset-2fa-requests') {
                  item.eventValue = res.PENDING;
                  this.runningLivePointForTickets()
                }
              })
            }
          })
        })
      }
    )).subscribe(noop, err => console.log(err))
  }

  runningLivePointForTickets() {
    this.items.map(ele => {
      if (ele.class1 == 'ticket') {
        ele.items?.map( item => {
          if (item.eventValue) {
            ele.event = true;
          }
        })
      }
    })
  }

  // ! ============================================================



}

export interface StatusCounts {
  removedTrue: number;
  removedFalse: number;
}